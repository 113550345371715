import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import axios from 'axios'

import { selectAvailebleVideos } from '../../store/slices/auth/authSlice'
import { useGetVideosQuery } from '../../store/slices/video/videoApiSlice'
import { setVideos } from '../../store/slices/video/videoSlice'

import { notify } from '../../utils/notify'

import Card from '../../components/ui/card'

import styles from './watch.module.scss'
import { selectActiveSubscription } from 'store/slices/subscription/subscriptionSlice'
import { useCancelSubscriptionMutation } from 'store/slices/subscription/subscriptionApiSlice'
import Button from 'components/ui/button'

const Watch = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetVideosQuery()
  const [cancelSubscription, {}] = useCancelSubscriptionMutation()

  const activeSubscription = useSelector(selectActiveSubscription)
  const location = useLocation()
  const dispatch = useDispatch()

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const cancelSubscriptionButtonHandler = async () => {
    let confirm = window.confirm('Вы уверены что хотите отменить поджписку?')
    if (confirm) {
      await cancelSubscription({ subscriptionId: activeSubscription._id }).unwrap()
    }
  }

  useEffect(() => {
    if (data) {
      dispatch(setVideos(data))
      return
    }
  }, [data, isLoading, isSuccess])

  return (
    <div className={styles.watch_wrapper}>
      <div className={styles.watch}>
        <div className={styles.goProfile}>
          <Link
            className='btn second'
            to={'/me'}
          >
            Вернуться К Профилю
          </Link>
        </div>
        {activeSubscription ? (
          <>
            <div className={styles.hasAccess}>
              <div className={styles.title}>
                <h4>Смотреть 1 Сезон</h4>
              </div>
              <div className={styles.content}>
                {isLoading && 'Загрузка...'}
                {isError && 'При загрузке произошла ошибка!'}
                {isSuccess && data && (
                  <div className={styles.items}>
                    {data?.videos?.map((card, i) => (
                      <Card
                        {...card}
                        key={i}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.noAccess}>
            <h4>У Вас нет доступа к просмотру</h4>
            <Link
              className={styles.getAccess}
              to='/rates'
            >
              Получить доступ &#8594;
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Watch
