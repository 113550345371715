import { Link, Outlet } from 'react-router-dom'

import Sidebar from '../../components/ui/tabs'
import ProfileForm from '../../components/ui/profile-form'
import Tabs from '../../components/ui/tabs'

import styles from './profile.module.scss'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/slices/auth/authSlice'
import clsx from 'clsx'
import { selectActiveSubscription } from 'store/slices/subscription/subscriptionSlice'

const ProfilePage = () => {
  const user = useSelector(selectCurrentUser)
  const activeSubscription = useSelector(selectActiveSubscription)
  const createdAt = new Date(user.createdAt)
  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return (
    <section className={styles.profile}>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <img
            src={
              user?.avatar
                ? user.avatar
                : `${process.env.REACT_APP_API_URL}/api/v1/static/images/no-image.jpg`
            }
            alt={user.name}
            className={styles.avatar_preview}
          />
        </div>
        <div className={styles.bio}>
          <div className={styles.block}>
            <span>Имя</span>
            <h4>{user.name}</h4>
          </div>
          <div className={styles.block}>
            <span>Почта</span>
            <p> {user.email}</p>
          </div>
          <div className={styles.block}>
            <span>Телефон</span>
            <p>{user.phone || 'Телефон не указан'}</p>
          </div>
          <div className={styles.created}>
            Зарегистрирован: <span>{createdAt.toLocaleDateString('ru-RU', dateOptions)}</span>
          </div>
        </div>
        <div className={styles.subscriptions}>
          {activeSubscription ? (
            <>
              <h4>Вам доступен просмотр сериала</h4>
              <Link
                className={styles.getAccess}
                to='/watch'
              >
                Смотреть 1 сезон &#8594;
              </Link>
              <p>Вступайте в наш VIP-чат</p>
              <Link
                to='https://t.me/+eYaX2sao_plkZGI6'
                target='_blank'
                className='btn second rounded subscribe'
              >
                <span>Вступить</span>
                <svg
                  width='48'
                  height='48'
                  viewBox='0 0 48 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
                    fill='url(#paint0_linear)'
                  />
                  <path
                    d='M8.93822 25.174C11.7438 23.6286 14.8756 22.3388 17.8018 21.0424C22.836 18.919 27.8902 16.8324 32.9954 14.8898C33.9887 14.5588 35.7734 14.2351 35.9484 15.7071C35.8526 17.7907 35.4584 19.8621 35.188 21.9335C34.5017 26.4887 33.7085 31.0283 32.935 35.5685C32.6685 37.0808 30.774 37.8637 29.5618 36.8959C26.6486 34.9281 23.713 32.9795 20.837 30.9661C19.8949 30.0088 20.7685 28.6341 21.6099 27.9505C24.0093 25.5859 26.5539 23.5769 28.8279 21.0901C29.4413 19.6088 27.6289 20.8572 27.0311 21.2397C23.7463 23.5033 20.5419 25.9051 17.0787 27.8945C15.3097 28.8683 13.2479 28.0361 11.4797 27.4927C9.89428 26.8363 7.57106 26.175 8.93806 25.1741L8.93822 25.174Z'
                    fill='white'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear'
                      x1='18.0028'
                      y1='2.0016'
                      x2='6.0028'
                      y2='30'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#37AEE2' />
                      <stop
                        offset='1'
                        stopColor='#1E96C8'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </Link>
            </>
          ) : (
            <>
              <h4>Чтобы смотреть сериал у Вас должен быть доступ</h4>
              <Link
                className={styles.getAccess}
                to='/rates'
              >
                Получить доступ &#8594;
              </Link>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default ProfilePage
