import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { notify } from 'utils/notify'
import Button from 'components/ui/button'

import styles from '../auth.module.scss'
import {
  useCheckOtpCodeMutation,
  useCheckRequestIDQuery,
  useGetOtpCodeMutation,
} from 'store/slices/auth/authApiSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/slices/auth/authSlice'
import { setSubs } from 'store/slices/subscription/subscriptionSlice'

const CheckOTP = () => {
  // Прверяем ссылку и и выводим соответствующую разметку
  const [searchParams, setSearchParams] = useSearchParams()
  const requestID = searchParams.get('requestID')
  const { data, isLoading, isError, isSuccess, error } = useCheckRequestIDQuery(requestID)

  const [
    checkOTP,
    { data: otpData, isLoading: isOtpLoading, isError: isOtpError, isSuccess: isOtpSuccess },
  ] = useCheckOtpCodeMutation()

  const [
    getOTP,
    {
      data: getOtpData,
      isLoading: isgetOtpLoading,
      isError: isgetOtpError,
      isSuccess: isgetOtpSuccess,
    },
  ] = useGetOtpCodeMutation()

  const [otp, setOtp] = useState('')
  const [canRefresh, setCanRefresh] = useState(false)
  const [timer, setTimer] = useState(59)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const from = location?.state?.from || '/me'

  const onSubmit = async (otp) => {
    try {
      await checkOTP({ otp, requestID }).unwrap()
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(data?.message, 'error')
    }
  }

  const getOTPHandler = async () => {
    try {
      await getOTP({ requestID }).unwrap()
      setTimer(59)
      setCanRefresh(false)
      setOtp('')
      notify('Код отправлен', 'success')
    } catch (error) {
      setOtp('')
      notify(`${error?.data?.message || 'Не удалось отправить код'}`, 'error')
    }
  }

  const otpHandler = (val) => {
    setOtp(val)
  }

  useEffect(() => {
    if (isSuccess) {
      const { lastGet } = data
      const difLastGet = new Date().getTime() / 1000 - new Date(lastGet).getTime() / 1000
      if (Math.floor(difLastGet) <= 59) {
        setCanRefresh(false)
        setTimer(59 - Math.floor(difLastGet))
      } else {
        setCanRefresh(true)
        setTimer(0)
      }
    }
  }, [isSuccess])

  useEffect(() => {
    const timerID = setInterval(() => {
      if (timer <= 0) {
        setCanRefresh(true)
      } else {
        setTimer((prev) => prev - 1)
      }
    }, 1000)
    return () => {
      clearInterval(timerID)
    }
  }, [timer])

  useEffect(() => {
    if (otp.length === 4) {
      onSubmit(otp)
    }
  }, [otp])

  useEffect(() => {
    if (isOtpSuccess) {
      const { type } = otpData
      if (type === 'register') {
        notify('Завершите регистрацию', 'success')
        navigate(`/auth/register?requestID=${requestID}`, { replace: true })
      }
      if (type === 'login') {
        const { accessToken, user, activeSubscription, subscriptions } = otpData
        dispatch(setUser({ accessToken, user }))
        dispatch(setSubs({ activeSubscription, subscriptions }))
        localStorage.setItem('accessToken', accessToken)
        notify(`Добро пожаловать ${user.name}`, 'success')
        navigate(from, { replace: true })
      }
      if (type === 'recovery') {
        notify('На Вашу почту был отправлен новый пароль', 'success')
        navigate(`/auth/login`, { replace: true })
      }
    }
    if (isError) {
      console.log(error)
      notify(`${error?.data?.message || 'Непредвиденная ошибка'}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtpError, isOtpSuccess])

  return (
    <>
      {isLoading && (
        <div className={styles.form}>
          <div className={clsx(styles.title, styles.title_otp)}>
            <h3>Загрузка...</h3>
            <p>{error?.data?.message}</p>
          </div>
        </div>
      )}
      {isError && (
        <div className={styles.form}>
          <div className={clsx(styles.title, styles.title_otp)}>
            <h3>Произошла ошибка</h3>
            <p>{error?.data?.message}</p>
          </div>
        </div>
      )}
      {isSuccess && data && (
        <div className={clsx(styles.form)}>
          <div className={clsx(styles.title, styles.title_otp)}>
            <h3>
              {data?.authType === 'login' && 'Вход'}
              {data?.authType === 'register' && 'Регистрация'}
              {data?.authType === 'recovery' && 'Сброс пароля'}
            </h3>
            <p>
              {data?.authMethod === 'phone' ? (
                <>
                  На номер <b>+{data?.message}</b> поступит звонок и робот продиктует 4-х значный
                  код
                </>
              ) : (
                <>
                  На почту <b>{data?.message}</b> придет письмо с 4-х значным кодом
                </>
              )}
            </p>
          </div>
          <div className={clsx(styles.inputs, styles.inputs_otp)}>
            <div className={clsx(styles.form_control, styles.form_control_otp)}>
              <OtpInput
                value={otp}
                onChange={otpHandler}
                numInputs={4}
                skipDefaultStyles={true}
                inputType='tel'
                shouldAutoFocus={true}
                containerStyle={clsx(styles.form_control_otp_box)}
                inputStyle={clsx(
                  isOtpError && styles.error_otp,
                  isOtpSuccess && styles.success_otp,
                )}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {/* {errors['otp'] && <span className={styles.error}>{errors['otp'].message}</span>} */}
            <div className={clsx(styles.resend)}>
              <p>Код действителен в течение 10 минут с момента отправки</p>
              <p>Не получили код?</p>
              {canRefresh ? (
                <button
                  className='btn prime'
                  onClick={getOTPHandler}
                >
                  Запросить код
                </button>
              ) : (
                <p>Запросить снова ({timer})</p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth'}>{`<-`}На шаг назад</Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth/login'}>Войти по логину и паролю</Link>
          </p>
        </div>
      </div>
    </>
  )
}
export default CheckOTP
