import { Parallax } from 'react-parallax'

import styles from './parolax.module.scss'

const ParolaxContainer = ({ children, text, bgImage, bgImageAlt = 'Фон' }) => (
  <Parallax
    blur={{ min: -20, max: 20 }}
    bgImage={bgImage}
    bgImageAlt={bgImageAlt}
    strength={300}
    className={styles.parolax}
    renderLayer={(percentage) => (
      <div
        className={styles.content}
        style={{
          opacity: percentage * 1.2,
        }}
      >
        {children}
      </div>
    )}
  >
    <div className={styles.container}></div>
  </Parallax>
)
export default ParolaxContainer
