import React from 'react'
import clsx from 'clsx'
import ReactPlayer from 'react-player'

import styles from './video-player.module.scss'

const VideoPlayer = ({ className, url, poster }) => {
  return (
    <>
      <ReactPlayer
        url={url}
        controls={true}
        width='100%'
        height='auto'
        playsinline={true}
        playing={true}
        // playIcon={<div></div>}
        config={{
          attributes: {
            poster,
            controls: true,
            controlsList: 'nodownload',
            preload: 'none',
            type: 'video/mp4',
          },
        }}
      />
      {/* <video
        className={styles.video}
        id='videoPlayer'
        controls
        playsInline
        poster={poster}
      >
        <source
          src={url}
          type={'video/mp4'}
        />
      </video> */}
    </>
  )
}

export default VideoPlayer
