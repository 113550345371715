import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  promocode: '',
  value: 0,
  discountType: '',
  rates: [],
}

const promocodeSlice = createSlice({
  name: 'promocode',
  initialState,
  reducers: {
    setPromocode: (state, action) => {
      state.promocode = action.payload.promocode
      state.value = action.payload.value
      state.discountType = action.payload.discountType
      state.rates = action.payload.rates
    },
    deletePromocode: (state, action) => {
      state.promocode = initialState.promocode
      state.value = initialState.value
      state.discountType = initialState.discountType
      state.rates = initialState.rates
    },
  },
})

export const { setPromocode, deletePromocode } = promocodeSlice.actions

export default promocodeSlice.reducer

export const selectPromocode = (state) => state.promocode.promocode
export const selectPromocodeData = (state) => state.promocode
