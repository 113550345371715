export const links = [
  {
    to: '/watch',
    title: 'Смотреть',
  },
  {
    to: '/about',
    title: 'О проекте',
  },
  {
    to: '/rates',
    title: 'Тарифы',
  },
  {
    to: '/contacts',
    title: 'Контакты',
  },
]
