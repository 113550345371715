import { apiSlice } from '../../api/apiSlice'

export const promocodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    activatePromocode: builder.mutation({
      query: (data) => ({
        url: `/promo/activate`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const { useActivatePromocodeMutation } = promocodeApiSlice
