import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useGetVideoQuery } from '../../../store/slices/video/videoApiSlice'

import VideoPlayer from '../video-player'
import Views from '../views'

import noticeIcon from '../../../assets/img/notice.svg'

import styles from './video-card.module.scss'
import { useEffect } from 'react'
import { notify } from 'utils/notify'
import Countdown from '../countdown'

const VideoCard = () => {
  const { id } = useParams()
  const { data, isLoading, isError, isSuccess, error } = useGetVideoQuery(id)

  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      notify(`${error?.data?.message || 'Ошибка'}`, 'error')

      if (error?.data?.status === 402) {
        navigate('/watch', { replace: true })
      }
    }
  }, [isError])

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        {isLoading && <h3>Загрузка...</h3>}
        {isError && <h3>Произошла ошибка при получении видео!</h3>}
        {isSuccess && data && (
          <>
            <Link
              className={styles.goBack}
              to='/watch'
            >
              {`< Назад`}
            </Link>
            <div className={styles.header}>
              <div className={styles.title}>
                <h4>{data.video.title}</h4>
                <p>{data.video.subTitle}</p>
              </div>
              <Views counter={data.video.views} />
            </div>
            <div className={styles.description}>
              <h4>Описание</h4>
              <p>{data.video.description}</p>
            </div>
            <div className={styles.body}>
              <Countdown
                notice='По окончании отсчета появится плеер'
                time={data.video.premierDate}
                dmode={true}
                title='До премьеры осталось'
              >
                <div className={styles.player}>
                  <VideoPlayer
                    url={`${process.env.REACT_APP_API_URL}/api/v1/watch/${data.video._id}`}
                    poster={`${process.env.REACT_APP_API_URL}/api/v1/static/posters/${data.video.poster}`}
                  />
                </div>
              </Countdown>
              <div className={styles.footer}>
                <div className={styles.comments}>
                  <h3>Комментарии</h3>
                  <div className={styles.comments_notice}>
                    <img
                      src={noticeIcon}
                      alt='Комментарии отключены'
                    />
                    <p>Комментарии для этой серии отключены!</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default VideoCard
