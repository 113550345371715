import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { useRecoveryPasswordMutation } from '../../../store/slices/auth/authApiSlice'
import { selectCurrentUser, setUser } from '../../../store/slices/auth/authSlice'

import { notify } from '../../../utils/notify'

import FormComponent from '../../../components/ui/form'

import styles from '../auth.module.scss'
import Button from 'components/ui/button'

const RecoveryPasswordPage = () => {
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })
  // * API Recovery Mutation
  const [recovery, { data: recoveryData, isLoading: isRecoveryLoading, isError: isRecoveryError }] =
    useRecoveryPasswordMutation()

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      const { email } = data
      if (email) {
        const newEmail = email.toLowerCase().trim()
        await recovery({ email: newEmail }).unwrap()
      }
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(`${data?.message || 'Непредвиденная ошибка'}`, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (recoveryData) {
      const { requestID } = recoveryData
      navigate(`/auth/check?requestID=${requestID}`)
    }
  }, [recoveryData])

  return (
    <>
      <form
        className={clsx(styles.form)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={clsx(styles.title)}>
          <h3>Сбросить Пароль</h3>
          <p>Введите почту</p>
        </div>
        <div className={clsx(styles.inputs)}>
          <div className={clsx(styles.form_control)}>
            <label htmlFor='email'>
              <span>
                Почта <b>*</b>
              </span>
              <input
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Это поле обязательно',
                  },
                })}
                id='email'
                type='email'
                placeholder='user@email.com'
              />
              {errors['email'] && <span className={styles.error}>{errors['email'].message}</span>}
            </label>
          </div>
          <div className={clsx(styles.form_control)}>
            <Button
              disabled={false}
              loading={isRecoveryLoading}
              type={'submit'}
              className={`btn prime fw`}
            >
              Далее
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth/login'}>Назад</Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            Нет аккаунта? <Link to={'/auth'}>Зарегистрироваться</Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default RecoveryPasswordPage
