import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'

import styles from './modal.module.scss'

const Modal = ({ onClose, children, className }) => {
  const [show, setShow] = useState(true)
  const modalOverlayRef = useRef(null)

  const backDropHandler = (e) => {
    if (modalOverlayRef?.current === e.target || e.keyCode === 27) {
      setShow(false)
      onClose()
    }
  }

  const handleCloseClick = (e) => {
    setShow(false)
    onClose()
  }

  useEffect(() => {
    window.addEventListener('click', backDropHandler)
    window.addEventListener('keydown', backDropHandler)
    const bodyTag = document.getElementById('body_id')
    bodyTag?.classList?.add('show_modal')

    return () => {
      window.removeEventListener('click', backDropHandler)
      window.removeEventListener('keydown', backDropHandler)
      bodyTag?.classList?.remove('show_modal')
    }
  }, [])

  const modalContent = (
    <>
      <div
        className={styles.overlay}
        ref={modalOverlayRef}
      ></div>
      <div className={clsx(styles.modal, className)}>
        <div className={styles.close}>
          <span onClick={handleCloseClick}>&times;</span>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </>
  )

  return show ? createPortal(modalContent, document.getElementById('modal-root')) : null
}

export default Modal
