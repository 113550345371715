import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectPromocode,
  setPromocode,
  deletePromocode,
  selectPromocodeData,
} from 'store/slices/promocode/promocodeSlice'
import { useActivatePromocodeMutation } from 'store/slices/promocode/promocodeApiSlice'

import { notify } from '../../utils/notify'

import Button from '../ui/button'

import styles from './promocode.module.scss'
import Modal from 'components/ui/modal'

const Promocode = ({ triggerText = 'Применить промокод', rateId }) => {
  const [inputVal, setInputVal] = useState('')
  const [show, setShow] = useState(false)

  const [activatePromocode, { data, isLoading, isSuccess, isError, error }] =
    useActivatePromocodeMutation()

  const promocodeData = useSelector(selectPromocodeData)

  const dispatch = useDispatch()

  const addPromocodeHandler = async () => {
    if (inputVal) {
      try {
        await activatePromocode({ promocode: inputVal, rateId }).unwrap()
      } catch (error) {
        console.log(error)
        // const { data } = error
        // notify(data?.message, 'error')
      }
      // dispatch(setPromocode({ promocode: inputVal }))
      // setShow(false)
    }
  }

  const deletePromocodeHandler = () => {
    setInputVal('')
    dispatch(deletePromocode())
  }

  useEffect(() => {
    if (isSuccess) {
      const { discountType, value, rates, promocode } = data
      let icon = discountType === 'percent' ? '%' : '₽'
      dispatch(setPromocode({ promocode, discountType, value, rates }))
      // localStorage.setItem('accessToken', accessToken)
      notify(`Скидка ${value}${icon}`, 'success')
      setShow(false)
    }
    if (isError) {
      console.log(error)
      notify(`${error?.data?.message || 'Непредвиденная ошибка'}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess])

  return (
    <div className={styles.promocode}>
      {promocodeData?.promocode && promocodeData?.rates?.includes(rateId) ? (
        <div className={styles.promocode_has}>
          Промокод: {promocodeData?.promocode} <span onClick={deletePromocodeHandler}>&times;</span>
        </div>
      ) : (
        <div
          className={styles.trigger}
          onClick={() => setShow(true)}
        >
          {triggerText} &#8594;
        </div>
      )}

      {show && (
        <Modal onClose={() => setShow(false)}>
          <div className={styles.wrapper}>
            <div className={styles.title}>Введите промокод</div>
            <div className={styles.form}>
              <input
                className={styles.input}
                type='text'
                placeholder='Промокод'
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
              <Button
                onClick={addPromocodeHandler}
                className={`btn prime ${styles.submit}`}
                loading={isLoading}
              >
                Применить
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Promocode
