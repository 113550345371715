import { Link } from 'react-router-dom'
import noticeIcon from '../../../assets/img/notice.svg'

import styles from './releases.module.scss'

const Releases = ({ title }) => {
  return (
    <div className={styles.releases}>
      {title && <h4>{title}</h4>}
      <ul>
        <li>
          1 серия <br />
          <Link to={'/watch/652e5b7eae98eddc8fa2bad4'}>
            <span>20 Сентября 2024</span>
          </Link>
        </li>
        <li>
          2 серия <br />
          <Link to={'/watch/656fe08f7ba765f03af9c7e4'}>
            <span>27 Сентября 2024</span>
          </Link>
        </li>
        <li>
          3 серия <br />
          <Link to={'/watch/656fe2097ba765f03af9c7e5'}>
            <span>04 Октября 2024</span>
          </Link>
        </li>
        <li>
          4 серия <br />
          <Link to={'/watch/656fe2327ba765f03af9c7e6'}>
            <span>09 Октября 2024</span>
          </Link>
        </li>
        <li>
          5 серия (конец 1 сезона) <br />
          <Link to={'/watch/656fe2547ba765f03af9c7e7'}>
            <span>14 Октября 2024</span>
          </Link>
        </li>
      </ul>
      <div className={styles.notice}>
        <img
          src={noticeIcon}
          alt='Важно'
        />
        <p>Дата выхода серий может поменяться на усмотрение автора</p>
      </div>
    </div>
  )
}
export default Releases
