import React, { useEffect, useState } from 'react'

import styles from './countdown.module.scss'
import { Link } from 'react-router-dom'

// function component
const AnimatedCard = ({ animation, digit, dmode }) => {
  return (
    <div className={`${styles.flipCard} ${styles[animation]} ${dmode && styles.dmode}`}>
      <span>{digit}</span>
    </div>
  )
}

// function component
const StaticCard = ({ position, digit, dmode }) => {
  return (
    <div className={`${styles[position]} ${dmode && styles.dmode}`}>
      <span>{digit}</span>
    </div>
  )
}

// function component
const FlipUnitContainer = ({ digit, shuffle, unit, dmode }) => {
  const units = {
    days: 'Дней',
    hours: 'Часов',
    minutes: 'Минут',
    seconds: 'Секунд',
  }
  // assign digit values
  let currentDigit = digit
  let previousDigit = digit + 1

  // to prevent a negative value
  if (unit !== 'hours') {
    previousDigit = previousDigit === -1 ? 59 : previousDigit
  } else {
    previousDigit = previousDigit === -1 ? 23 : previousDigit
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`
  }

  return (
    <div className={`${styles.flipUnitContainer} ${dmode && styles.dmode}`}>
      <p>{units[unit]}</p>
      <StaticCard
        position={'upperCard'}
        digit={currentDigit}
        dmode={dmode}
      />
      <StaticCard
        position={'lowerCard'}
        digit={previousDigit}
        dmode={dmode}
      />
      <AnimatedCard
        digit={shuffle ? previousDigit : currentDigit}
        animation={`${shuffle ? 'fold' : 'unfold'}`}
        dmode={dmode}
      />
      <AnimatedCard
        digit={!shuffle ? previousDigit : currentDigit}
        animation={`${!shuffle ? 'fold' : 'unfold'}`}
        dmode={dmode}
      />
    </div>
  )
}

const Countdown = ({
  dmode = false,
  title = 'До премьеры 1-ой серии',
  notice = '',
  time = 'Sep 20 2024 21:00:00',
  children,
}) => {
  const [targetTimestamp, setTargetTimestamp] = useState(new Date(time).getTime())

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const [daysShuffle, setDaysShuffle] = useState(true)
  const [hoursShuffle, setHoursShuffle] = useState(true)
  const [minutesShuffle, setMinutesShuffle] = useState(true)
  const [secondsShuffle, setSecondsShuffle] = useState(true)

  const updateTime = () => {
    const time = Math.round((targetTimestamp - new Date().getTime()) / 1000)

    const d = Math.floor(time / 3600 / 24)
    const h = Math.floor((time / 60 / 60) % 24)
    const m = Math.floor((time % 3600) / 60)
    const s = Math.floor((time % 3600) % 60)

    if (d !== days) {
      setDaysShuffle(!daysShuffle)
      setDays(d)
    }
    if (h !== hours) {
      setHoursShuffle(!hoursShuffle)
      setHours(h)
    }
    if (m !== minutes) {
      setMinutesShuffle(!minutesShuffle)
      setMinutes(m)
    }
    if (s !== seconds) {
      setSecondsShuffle(!secondsShuffle)
      setSeconds(s)
    }
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      updateTime()
    }, 50)

    return () => {
      clearInterval(countdown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsShuffle])

  if (targetTimestamp / 1000 <= new Date().getTime() / 1000) {
    return children
  }

  return (
    <div className={`${styles.countdown} ${dmode && styles.dmode}`}>
      <h3>{title}</h3>
      <div className={styles.flipClock}>
        <FlipUnitContainer
          unit={'days'}
          digit={days}
          shuffle={daysShuffle}
          dmode={dmode}
        />
        <FlipUnitContainer
          unit={'hours'}
          digit={hours}
          shuffle={hoursShuffle}
          dmode={dmode}
        />
        <FlipUnitContainer
          unit={'minutes'}
          digit={minutes}
          shuffle={minutesShuffle}
          dmode={dmode}
        />
        <FlipUnitContainer
          unit={'seconds'}
          digit={seconds}
          shuffle={secondsShuffle}
          dmode={dmode}
        />
      </div>
      <p className={styles.notice}>{notice}</p>
    </div>
  )
}

export default Countdown
