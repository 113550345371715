import styles from './socials.module.scss'

import vkIcon from '../../../assets/img/svg/vk.svg'
import instIcon from '../../../assets/img/svg/inst.svg'
import tgIcon from '../../../assets/img/svg/tg.svg'
import youtubeIcon from '../../../assets/img/svg/youtube.svg'
import rutubeIcon from '../../../assets/img/svg/rutube.svg'

const Socials = ({ items = ['vk'] }) => {
  const social = [
    {
      id: 'vk',
      link: 'https://vk.com/filmthehero',
      icon: vkIcon,
    },
    {
      id: 'inst',
      link: 'https://www.instagram.com/herofilm2024',
      icon: instIcon,
    },
    {
      id: 'tg',
      link: 'https://t.me/filmthehero',
      icon: tgIcon,
    },
    {
      id: 'youtube',
      link: 'https://www.youtube.com/channel/UCNEBRwxAB83TEB3MmgtyANA',
      icon: youtubeIcon,
    },
    {
      id: 'rutube',
      link: 'https://rutube.ru/channel/42253031/',
      icon: rutubeIcon,
    },
  ]
  const socialItems = items.map((s) => {
    return social.find((item) => item.id === s)
  })
  return (
    <ul className={styles.socials}>
      {socialItems.map((social) => {
        return (
          <li key={social.id}>
            {social.id === 'inst' && (
              <div className={styles.tooltip}>
                *признана экстремистской организацией и запрещена на территории РФ
              </div>
            )}
            <a
              href={social.link}
              target='_blank'
              rel='noindex nofollow noreferrer'
            >
              <img
                src={social.icon}
                alt={`${social.id} icon`}
              />
            </a>
          </li>
        )
      })}
    </ul>
  )
}
export default Socials
